import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import "./Assets/scss/main.scss";
import Home from "./Containers/Home"
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Naaiatelier from "./Containers/Naaiatelier";
import Fournituren from "./Containers/Fournituren";
import Breien from "./Containers/Breien";
import Producten from "./Containers/Producten";
import NaaimachineReparatie from "./Containers/NaaimachineReparatie";
import Contact from "./Containers/Contact";
import OpeningHours from "./Containers/OpeningHours";

export default function App() {
  return (
    <Router>
      <Header/>

      <Switch>
        <Route path="/naaiatelier">
          <Naaiatelier />
        </Route>
        <Route path="/fournituren">
          <Fournituren />
        </Route>
        <Route path="/breien-haken">
          <Breien />
        </Route>
        <Route path="/producten">
          <Producten />
        </Route>
        <Route path="/naaimachine-reparatie">
          <NaaimachineReparatie />
        </Route>
        <Route path="/contact">
          <Contact />
        </Route>
        <Route path="/openingstijden">
          <OpeningHours/>
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>

      <Footer />
    </Router>
  );
}
