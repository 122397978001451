import React from 'react';

class Footer extends React.Component {
  render() {
    return (
      <footer className="container bg-warning font-weight-bold text-light p-4 mt-4 rounded">
        <div>De Magneet | Dorpsweg 56 | 8271BN IJsselmuiden | 038-3313346 | <a
          href="mailto:Info@de-magneet.nl">Info@de-magneet.nl</a> | <a
          href="https://www.facebook.com/Aafke-De-magneet-522590124583273/" target="_blank" rel="noreferrer"
          title="Facebookpagina de Magneet">Facebook</a> | <a href="https://instagram.com/demagneet" target="_blank"
                                                           rel="noreferrer"
                                                           title="Instagrampagina de Magneet">Instagram</a></div>
      </footer>
    );
  }
}

export default Footer;
