import React from 'react';
import OpeningHoursToday from "../../Components/OpeningHoursToday";
import { Helmet } from "react-helmet";
import {Alerts} from "../../Constants/Alerts";
import Alert from "../../Components/Alert";
import Address from "../../Components/Address";

class Naaiatelier extends React.Component {
  render() {
    return (
      <div className="container p-0">

        <Helmet>
          <html lang="nl" />
          <title>De Magneet - Naaiatelier</title>
          <meta name="description" content="U kunt bij ons terecht voor al uw kledingreparatie en het vermaken van kleding." />
        </Helmet>

        {Alerts.map((alert, index) => (
          <Alert key={index} type={alert.type} message={alert.message} />
        ))}

        <div className="row align-items-start gx-2">
          <div className="col">
            <div className="bg-light p-3 shadow-sm rounded">
              <h1 className="mb-3">Naaiatelier</h1>
              <p>
              U kunt bij ons terecht voor al uw kledingreparatie en het vermaken van kleding.
Deze kunt u brengen in onze winkel aan de Dorpsweg. Er is ook een paskamer aanwezig. Voor het vermaken van trouwjurken, maatpakken en galakleding kunt u het beste eerst even contact opnemen. Dan maken we een afspraak op een rustiger tijdstip.
Voor overige vragen neem ook gerust contact op.
              </p>
              <p>De volgende naaiwerkzaamheden kunnen wij helaas niet meer aannemen:</p>
              <ul>
                <li>Gordijnen;</li>
                <li>Plaatsen van nieuwe ritsen in jassen;</li>
                <li>Kleding waar (door iemand anders als wij zelf) al aan is genaaid, geknipt of getornd;</li>
                <li>Reparatie van/aan leer;</li>
                <li>Reparatie aan zeilen, tenten, luifels etc.</li>
              </ul>

            </div>
          </div>
          <aside className="col-md-3 mt-md-0 mt-4">
            <Address />
            <OpeningHoursToday />
          </aside>
        </div>
      </div>
    );
  }
}

export default Naaiatelier;
