import React from 'react';

class HeaderImage extends React.Component {
  render() {
    const slideImages = [
      'slides/1.jpg',
      'slides/2.jpg',
      'slides/3.jpg',
      'slides/4.jpg',
      'slides/5.jpg',
    ];

    return (
      <img className="w-100 img-fluid mb-4 rounded" loading="lazy" src={slideImages[Math.floor(Math.random() * slideImages.length)]} alt="" />
    )
  }
}

export default HeaderImage;
