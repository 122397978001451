import React from 'react';
import OpeningHoursToday from "../../Components/OpeningHoursToday";
import HeaderImage from "../../Components/HeaderImage";
import { Helmet } from "react-helmet";
import {Alerts} from "../../Constants/Alerts";
import Alert from "../../Components/Alert";
import Address from "../../Components/Address";

class Home extends React.Component {
  render() {
    return (
      <div className="container p-0">

        <Helmet>
          <html lang="nl" />
          <title>De Magneet - Kledingreparatie en Fournituren</title>
          <meta name="description" content="Verkoop van garen, ritsen, knopen, lint, Botter IJsselmuiden wol, sokkenwol, kantjes, bandjes vanuit IJsselmuiden (gemeente Kampen)." />
        </Helmet>

        <div className="rounded">
          <HeaderImage />
        </div>

        {Alerts.map((alert, index) => (
          <Alert key={index} type={alert.type} message={alert.message} />
        ))}

        <div className="row align-items-start gx-2">
          <div className="col">
            <div className="bg-light p-3 shadow-sm rounded">
              <h1 className="mb-3">De Magneet <br /><span class="fs-3 text-primary fst-italic mt-4">Uw winkel voor kledingreparatie, fournituren, brei- en haakbenodigdheden</span></h1>
              <p>
              De Magneet is een naaiatelier, fournituren en wolwinkel in het centrum van IJsselmuiden. De winkel is te vinden aan de dorpsweg 56. Ook kunt u daar terecht voor het brengen en halen naaiwerk.
              Het naaiatelier zelf is in het pand ernaast gevestigd, maar verder gesloten.
              </p>
              <p>Voor meer informatie over het atelier en de producten die we aanbieden kunt u kijken in het menu hierboven.
                Heeft u verder nog vragen, bel of mail ons gerust. Onze contactgegevens kunt u vinden aan de rechterkant en onderaan de website.</p>
              <p>Parkeren kan bij ons voor de deur en u kunt zowel contant betalen als pinnen.</p>
              <p>Voor meer informatie over openingstijden, aanbiedingen en nieuwe producten kunt u ook eens kijken op onze socials: <a href="https://www.facebook.com/Aafke-De-magneet-522590124583273/" target="_blank" rel="noreferrer" title="Facebookpagina de Magneet">Facebook</a> - <a href="https://instagram.com/demagneet" target="_blank" rel="noreferrer" title="Instagrampagina de Magneet">Instagram</a></p>
            </div>
          </div>
          <aside className="col-md-3 mt-md-0 mt-4">
            <Address />
            <OpeningHoursToday />
          </aside>
        </div>
      </div>
    );
  }
}

export default Home;
