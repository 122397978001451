import React from 'react';
import { OpeningHoursData } from '../../Constants/OpeningHours';
import {NavLink} from "react-router-dom";

class OpeningHoursToday extends React.Component {
  render() {
    const day = new Date().toLocaleString('NL-NL', {  weekday: 'long' });
    const data = OpeningHoursData.find(element => element.day.toLowerCase() === day);
    return (
      <div className="sb-block shadow-sm bg-light p-3 mb-2 rounded">
        <h2 className="h5 mb-3">Openingstijden vandaag</h2>
        <dl>
          <dt className="fw-bold border-bottom pb-1 mb-2">Ochtend</dt>
          <dd>{data.morning}</dd>
          <dt className="fw-bold border-bottom pb-1 mb-2">Middag</dt>
          <dd>{data.afternoon}</dd>
        </dl>
        <div className="small text-end">
          <NavLink exact className="" to="/openingstijden">Meer</NavLink></div>
      </div>
    );
  }
}

export default OpeningHoursToday;
