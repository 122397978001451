import React from 'react';
import OpeningHoursToday from "../../Components/OpeningHoursToday";
import { Helmet } from "react-helmet";
import {Alerts} from "../../Constants/Alerts";
import Alert from "../../Components/Alert";
import Address from "../../Components/Address";

class NaaimachineReparatie extends React.Component {
  render() {
    return (
      <div className="container p-0">

        <Helmet>
          <html lang="nl" />
          <title>De Magneet - Naaimachine reparatie</title>
          <meta name="description" content="U kunt bij ons terecht voor uw naaimachine onderhoud en reparatie. Dit wordt gedaan door Rietberg Machine Service." />
        </Helmet>

        {Alerts.map((alert, index) => (
          <Alert key={index} type={alert.type} message={alert.message} />
        ))}

        <div className="row align-items-start gx-2">
          <div className="col">
            <div className="bg-light p-3 shadow-sm rounded">
              <h1 className="mb-3">Naaimachine reparatie</h1>
              <p>U kunt bij ons terecht voor uw naaimachine onderhoud en reparatie. Dit wordt gedaan door Rietberg
                Machine Service. U kunt uw machine gewoon brengen en halen in de winkel.</p>
              <p>Via Rietberg Machine Service hebben wij een aantal tweedehands naaimachines te koop.</p>
              <br />
              <h2 className="mt-4">Prijslijst</h2>

              <h3 className="d-flex border-bottom justify-content-lg-between"><span>APK</span><span>€75,-</span></h3>
              <p>Bij een APK wordt uw gehele machine nagezien.
                Alle spelingen en toleranties worden gecontroleerd en bijgesteld waar nodig.
                Uw machine krijgt een volledige onderhoudsbeurt, tevens worden elektrische componenten nagekeken.
                Exclusief het vervangen van onderdelen, deze worden apart in rekening gebracht.</p>

              <h3 className="d-flex border-bottom justify-content-lg-between"><span>Schoonmaken smeren</span><span>€65,-</span></h3>
              <p>Bij een schoonmaakbeurt wordt oude olie stof en draad resten verwijderd.
                Alle benodigde onderdelen worden opnieuw gesmeerd.
                Exclusief het vervangen van onderdelen, deze worden apart in rekening gebracht.</p>

              <h3 className="d-flex border-bottom justify-content-lg-between"><span>Diagnose stellen</span><span>€40,-</span></h3>
              <p>Hier wordt gekeken wat er mis is met uw machine, reparaties worden uitgevoerd na overleg.
                Dit word berekend middels een uurtarief.</p>

              <h3 className="d-flex border-bottom justify-content-lg-between"><span>Reparaties uurtarief</span><span>€25,-</span></h3>
              <p>Wanneer er een diagnose vastgesteld is, wordt de reparatie uitgevoerd.
                Dit wordt berekend middels een uurtarief. <br />Exclusief het vervangen van onderdelen, deze worden
                apart in rekening gebracht.</p>
              <p className="fw-bold">Al onze prijzen zijn inclusief 21% BTW<br />
                Op al onze onderhouds- of reparatiewerkzaamheden geven wij 6 maanden garantie.</p>
            </div>
          </div>
          <aside className="col-md-3 mt-md-0 mt-4">
            <Address />
            <OpeningHoursToday />
          </aside>
        </div>
      </div>
    );
  }
}

export default NaaimachineReparatie;
