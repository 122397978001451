import React from 'react';
import OpeningHoursToday from "../../Components/OpeningHoursToday";
import { Helmet } from "react-helmet";
import {Alerts} from "../../Constants/Alerts";
import Alert from "../../Components/Alert";
import Address from "../../Components/Address";

class Producten extends React.Component {
  render() {
    return (
      <div className="container p-0">

        <Helmet>
          <html lang="nl" />
          <title>De Magneet - Producten</title>
          <meta name="description" content="p het gebied van borduren hebben we een grote kast met dmc garen staan en daarnaast verschillende soorten stramien en borduurnaalden" />
        </Helmet>

        {Alerts.map((alert, index) => (
          <Alert key={index} type={alert.type} message={alert.message} />
        ))}

        <div className="row align-items-start gx-2">
          <div className="col">
            <div className="bg-light p-3 shadow-sm rounded">
              <h1 className="mb-4">Overige producten</h1>
              <h2>Borduren</h2>
              <p>Op het gebied van borduren hebben we een grote kast met dmc garen staan en daarnaast verschillende
                soorten stramien en borduurnaalden.</p>
              <h2>Stoffen</h2>
              <p>Wij hebben verschillende merken en kleuren voeringsstoffen op voorraad. Ook thule en tussenvoering van vlieseline kunt u bij ons vinden.</p>
            </div>
          </div>
          <aside className="col-md-3 mt-md-0 mt-4">
            <Address />
            <OpeningHoursToday />
          </aside>
        </div>
      </div>
    );
  }
}

export default Producten;
