import React from 'react';
import OpeningHoursToday from "../../Components/OpeningHoursToday";
import {Helmet} from "react-helmet";

class Contact extends React.Component {
  render() {
    return (
      <div className="container p-0">
        <Helmet>
          <html lang="nl" />
          <title>Contact | De Magneet - Kledingreparatie en Fournituren</title>
          <meta name="description" content="Contact opnemen met De Magneet - Kledingreparatie en Fournituren" />
        </Helmet>

        <div className="row align-items-start gx-2">
          <div className="col">
            <div className="bg-light shadow-sm p-3 rounded">
              <h1 className="mb-3">Contact</h1>
              <p className="h5">Ons adres:</p>
              <div itemProp="address" itemScope itemType="http://schema.org/PostalAddress">
                <div itemProp="streetAddress">Dorpsweg 54</div>
                <div itemProp="postalCode">8272 BN</div>
                <div itemProp="addressLocality">IJsselmuiden</div>
                <div className="d-none" itemProp="addressCountry">NL</div>
              </div>
              <div itemProp="telephone">038 3313346</div>
              <a href="mailto:info@de-magneet.nl" itemProp="email" title="Stuur ons een email">info@de-magneet.nl</a>

              <iframe loading="lazy" title="Kaart met het adres van onze winkel"
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2425.2325179789077!2d5.925281315447384!3d52.565406240616156!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c878880a63d25d%3A0x7fbafa3acc6874ce!2sAafke+-+De+Magneet!5e0!3m2!1snl!2snl!4v1449081593888"
                      allowFullScreen="" width="100%" height="450" frameBorder="0" className="mt-4 rounded"/>

            </div>
          </div>
          <aside className="col-md-3 mt-md-0 mt-4">
            <OpeningHoursToday />
          </aside>
        </div>
      </div>
    );
  }
}

export default Contact;
