import React from 'react';
import parse from 'html-react-parser';

function Alert(props) {
  const {type, message} = props;
  return (
      <div className={`alert alert-${type}`} role="alert">
        {parse(message)}
      </div>
    );
}

export default Alert;
