import React from 'react';
import {NavLink} from "react-router-dom";

class Address extends React.Component {
  render() {
    return (
      <div className="sb-block shadow-sm bg-light p-3 mb-2 rounded">
        <h2 className="h5 mb-3">Adresgegevens</h2>
        <div className="list">
          <div itemProp="address" itemScope itemType="https://schema.org/PostalAddress"></div>
          <div itemProp="streetAddress">Dorpsweg 56</div>
          <div>
            <span itemProp="postalCode">8271BN</span>,
            <span itemProp="addressLocality"> IJsselmuiden</span>
          </div>
          <div><a href="tel:0383313346" itemProp="telephone">038-3313346</a></div>
          <div><a href="mail:info@de-magneet.nl" itemProp="email">info@de-magneet.nl</a></div>
          <div className="small text-end">
            <NavLink exact className="" to="/contact" >Meer</NavLink></div>
        </div>
      </div>
    );
  }
}

export default Address;
