import React from 'react';
import OpeningHoursToday from "../../Components/OpeningHoursToday";
import { Helmet } from "react-helmet";
import {Alerts} from "../../Constants/Alerts";
import Alert from "../../Components/Alert";
import Address from "../../Components/Address";

class Breien extends React.Component {
  render() {
    return (
      <div className="container p-0">

        <Helmet>
          <html lang="nl" />
          <title>De Magneet - Breien</title>
          <meta name="description" content="Wij hebben een uitgebreid assortiment aan haak en brei benodigdheden." />
        </Helmet>

        {Alerts.map((alert, index) => (
          <Alert key={index} type={alert.type} message={alert.message} />
        ))}

        <div className="row align-items-start gx-2">
          <div className="col">
            <div className="bg-light p-3 shadow-sm rounded">
              <h1 className="mb-3">Breien & Haken</h1>
              <p>
                Wij hebben een uitgebreid assortiment aan haak en brei benodigdheden.
              </p>
              <p>Wij verkopen onder andere de volgende merken:</p>
              <ul>
                <li>Scheepjes</li>
                <li>Botterwol IJsselmuiden</li>
                <li>Schachenmayr</li>
                <li>Durable</li>
                <li>Opal sokkenwol</li>
              </ul>
              <ul>
                <li>Prym</li>
                <li>Clover</li>
                <li>Knitpro</li>
                <li>Opry</li>
              </ul>
              <p>Ook hebben wij een groot aantal knopen, veiligheidsoogjes, muziekdoosjes, bijtringen, mandala ringen,
                enz om uw project helemaal af te maken. </p>


            </div>
          </div>
          <aside className="col-md-3 mt-md-0 mt-4">
            <Address />
            <OpeningHoursToday />
          </aside>
        </div>
      </div>
    );
  }
}

export default Breien;
