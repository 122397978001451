import React from 'react';
import Table from 'rc-table';
import {OpeningHoursData, OpeningHourscolumns} from '../../Constants/OpeningHours';
import { css } from '@emotion/css'
import {Helmet} from "react-helmet";
import Address from "../../Components/Address";
import {Alerts} from "../../Constants/Alerts";
import Alert from "../../Components/Alert";

class OpeningHours extends React.Component {
  render() {
    const body = css({ width: '100%' });

    return (
      <div className="container p-0">

        <Helmet>
          <html lang="nl" />
          <title>Openingstijden | De Magneet - Kledingreparatie en Fournituren</title>
          <meta name="description" content="Openingstijden van De Magneet - Kledingreparatie en Fournituren" />
          <meta name="geo.position" content="52.56550888316501 5.9276409908246706;" />
          <meta name="geo.placename" content="Ijsselmuiden" />
          <meta name="geo.region" content="NL" />
        </Helmet>

        {Alerts.map((alert, index) => (
          <Alert key={index} type={alert.type} message={alert.message} />
        ))}

        <div className="row align-items-start gx-2">
          <div className="col">
            <div className="bg-light p-3 shadow-sm rounded">
              <h1 className="mb-3">Openingstijden</h1>
              <Table
                columns={OpeningHourscolumns}
                data={OpeningHoursData}
                showHeader={false}
                className={body}
                tableLayout="fixed"
              />
            </div>
          </div>
          <aside className="col-md-3 mt-md-0 mt-4">
            <Address />
          </aside>
        </div>
      </div>
    );
  }
}

export default OpeningHours;
