import React from 'react';
import OpeningHoursToday from "../../Components/OpeningHoursToday";
import { Helmet } from "react-helmet";
import {Alerts} from "../../Constants/Alerts";
import Alert from "../../Components/Alert";
import Address from "../../Components/Address";

class Fournituren extends React.Component {
  render() {
    return (
      <div className="container p-0">

        <Helmet>
          <html lang="nl" />
          <title>De Magneet - Fournituren</title>
          <meta name="description" content=" Wij hebben een groot assortiment aan fournituren. Hierbij kunt u denken aan knopen, drukknopen, biaisband, kant, band, ritsen, koort, klittenband en elastiek." />
        </Helmet>

        {Alerts.map((alert, index) => (
          <Alert key={index} type={alert.type} message={alert.message} />
        ))}

        <div className="row align-items-start gx-2">
          <div className="col">
            <div className="bg-light p-3 shadow-sm rounded">
              <h1 className="mb-3">Fournituren</h1>
              <p>
                Wij hebben een groot assortiment aan fournituren. Hierbij kunt u denken aan knopen, drukknopen, biaisband, kant, band, ritsen, koord, klittenband en elastiek.
                Ook alle materialen die u nodig heeft om te naaien zijn te vinden in de winkel, u kunt hierbij denken aan garen, naalden, spelden, meetgerei en patroonpapier.
                Producten voor het onderhouden van uw naaimachine zijn ook in de winkel aanwezig.
              </p>
              <p>Wij hebben onder andere de volgende merken fournituren:</p>
              <ul>
                <li>Prym</li>
                <li>Gütermann allesnaaigaren</li>
                <li>Gütermann maraflex</li>
                <li>Goldman</li>
                <li>YKK</li>
                <li>Restyle</li>
                <li>Schmetz</li>
              </ul>
            </div>
          </div>
          <aside className="col-md-3 mt-md-0 mt-4">
            <Address />
            <OpeningHoursToday />
          </aside>
        </div>
      </div>
    );
  }
}

export default Fournituren;
